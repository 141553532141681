import * as React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Alert from "react-bootstrap/Alert"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { isLangEng, isLangIt, getAndSetLang} from "../utils/language"

import "../styles/404.scss"

const NotFoundPage = () => {

  const paragraphStyles = {
    marginBottom: 48,
  }

  const imageStyle = {
    maxWidth: "100%",
    width: "100%",
    margin:"auto"
  }

  let lang = getAndSetLang("it")

  let content
  let title

  if( isLangIt() ){
    title="404 Nessun risultato"
    content =
    <>
      <Alert variant="danger">
        <h1 style={{textAlign: "center"}}>
          404 - Nessun risultato
        </h1>
      </Alert>
      <p style={paragraphStyles}>
        Sembra che tu sia un po' terra piattista... Hai proprio perso la retta via.
      </p>
      <StaticImage src="https://i0.wp.com/metro.co.uk/wp-content/uploads/2019/03/SEI_46629444_1599043320.jpg?quality=90&strip=all&w=1200&h=630&crop=1&zoom=1&ssl=1"
        alt="Metro.co.uk image of a flath earth in space" style={imageStyle} />
      <p style={paragraphStyles}>Naah, non è proprio così.</p>
      <StaticImage src="https://i.ytimg.com/vi/34T5iHUlwkQ/maxresdefault.jpg"
        alt="Image of a convex earth"  style={imageStyle} />
      <p style={paragraphStyles}>
        Magari è più simile a questa, magari messa in un pianeta gargantuesco ghiacciato gigantesco
        che è grante quanto il sole, che ne dici? 😏
      </p> 
      <p style={paragraphStyles}>
          Ok, basta scherzi. Ma ammettiamolo, la terra vista dalla luna è fantastica.
      </p>
      <StaticImage src="http://www.zonu.com/images/0X0/2009-11-06-10885/The-Earth-from-the-Moon.jpg"
        alt="The earth as seen from the moon"  style={imageStyle} />
      <p style={paragraphStyles}>
        Aaahhh... Mi piacerebbe proprio andare lassù... Chissà, forse... Un giorno...
      </p>
      <p style={paragraphStyles}>
        <Link to="/">Clicca qui</Link> 
        per tornare a casa.
      </p> 
    </>
  }
  if( isLangEng() ){
    title="404 Not found"
    content = 
      <>
        <Alert variant="danger">
          <h1 style={{textAlign: "center"}}>
            404 - Not found
          </h1>
        </Alert>
       <p style={paragraphStyles}>
        It seems you became a little flat-earther... You surely lost the right way.
      </p>
      <StaticImage src="https://i0.wp.com/metro.co.uk/wp-content/uploads/2019/03/SEI_46629444_1599043320.jpg?quality=90&strip=all&w=1200&h=630&crop=1&zoom=1&ssl=1"
        alt="Metro.co.uk image of a flath earth in space" style={imageStyle} />
      <p style={paragraphStyles}>Naah, it's not exactly like this.</p>
      <StaticImage src="https://i.ytimg.com/vi/34T5iHUlwkQ/maxresdefault.jpg"
        alt="Image of a convex earth"  style={imageStyle} />
      <p style={paragraphStyles}>
        Maybe it's more similar to this, maybe on a gargantuan iced planet 
        big as the sun, what do you think? 😏
      </p> 
      <p style={paragraphStyles}>
          Ok, enough jokes. But seriously, the earth seen from the moon is wonderful.
      </p>
      <StaticImage src="http://www.zonu.com/images/0X0/2009-11-06-10885/The-Earth-from-the-Moon.jpg"
        alt="The earth as seen from the moon"  style={imageStyle} />
      <p style={paragraphStyles}>
        Aaahhh... I would like to got there... Who knows, maybe... Someday...
      </p>
      <p style={paragraphStyles}>
        <Link to="/">Clicca here</Link> 
        to go home.
      </p> 
      </>
  }
          

  return (
      <Layout lang={lang} pageTitle="">
        {content}
      </Layout>)

}

export default NotFoundPage

export const Head = () => <Seo title={"404"} />
